/**nav {
  width: 100%;
  height: 20px;
}
nav a {
  padding: 10px;
  font-size: 18px;
}

nav a.active {
  background-color: green;
  color: #fff;
  padding: 5px;
}
**/
nav a.active {
  color: var(--tblr-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--tblr-dropdown-link-active-bg)
}